const surveyJSON = {
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'panel',
          name: 'panel1',
          elements: [
            {
              type: 'html',
              name: 'intro',
              hideNumber: true,
              html: '<h5>This questionnaire consists of a list of 32 statements describing various behaviors and 3 open-ended questions for capturing qualitative comments. Takes about 7-10 minutes to complete. All questions need to be responded to.<br><br>\nWhen you are done answering all of the questions, click “Complete” at the end of the page. The "thank you for your response" message indicates the successful submission of your response.<br><br> *****  </h5>',
            },
          ],
        },
        {
          type: 'text',
          name: 'name',
          title: 'Name',
          hideNumber: true,
          isRequired: true,
        },
        {
          type: 'text',
          name: 'email',
          title: 'Email-id',
          hideNumber: true,
          isRequired: true,
          inputType: 'email',
        },
      ],
    },
    {
      name: 'page2',
      elements: [
        {
          type: 'matrix',
          name: 'self1',
          title:
            'Please indicate the level of consistency with which you demonstrate the following behaviors at workplace. All statements need to be responded to.',
          hideNumber: true,
          columns: [
            {
              value: '1',
              text: 'Never',
            },
            {
              value: '2',
              text: 'Rarely',
            },
            {
              value: '3',
              text: 'Occasionally',
            },
            {
              value: '4',
              text: 'Frequently',
            },
            {
              value: '5',
              text: 'Always',
            },
            {
              value: '6',
              text: "Don't Know",
            },
          ],
          rows: [
            {
              value: 'S_1_1',
              text: "I put the organization's interest before my own",
            },
            {
              value: 'S_1_2',
              text: 'I set the team for success',
            },
            {
              value: 'S_1_3',
              text: 'I use the team resources and time wisely',
            },
            {
              value: 'S_1_4',
              text: 'I demonstrate ownership and clear accountability',
            },
            {
              value: 'S_1_5',
              text: 'I articulate the purpose and vision for the future',
            },
            {
              value: 'S_2_1',
              text: 'I am open to and appreciate different perspectives',
            },
            {
              value: 'S_2_2',
              text: 'I listen attentively without interrupting',
            },
            {
              value: 'S_2_3',
              text: 'I persevere through uncertainty and failures',
            },
            {
              value: 'S_2_4',
              text: "I understand others' viewpoints, when different from my own",
            },
            {
              value: 'S_2_5',
              text: "I understand the reasons for others' actions",
            },
            {
              value: 'S_3_1',
              text: 'I provide constructive feedback to facilitate growth',
            },
            {
              value: 'S_3_2',
              text: 'I empower the team and believe in their capabilities',
            },
            {
              value: 'S_3_3',
              text: 'I am aware of and in control of my emotions',
            },
            {
              value: 'S_3_4',
              text: 'I resolve conflict by bringing disagreements into the open',
            },
            {
              value: 'S_3_5',
              text: 'I inspire the team to work towards change',
            },
            {
              value: 'S_4_1',
              text: 'I set clear goals and vision for the team to follow',
            },
            {
              value: 'S_4_2',
              text: 'I adapt quickly to changing market conditions',
            },
            {
              value: 'S_4_3',
              text: 'I continuously raise the bar',
            },
            {
              value: 'S_4_4',
              text: 'I relentlessly drive to get the best from the teams',
            },
            {
              value: 'S_4_5',
              text: 'I am undeterred by setbacks and course-correct quickly',
            },
            {
              value: 'S_5_1',
              text: 'I see issues more than opportunities',
            },
            {
              value: 'S_5_2',
              text: 'I encourage creative solutions to address routine challenges',
            },
            {
              value: 'S_5_3',
              text: 'I strive to stay informed on the latest technological trends',
            },
            {
              value: 'S_5_4',
              text: 'I skillfully apply technology(ies) to solve problems',
            },
            {
              value: 'S_5_5',
              text: 'I leverage automation to improve process efficacy',
            },
            {
              value: 'S_6_1',
              text: 'I consistently stick to commitments',
            },
            {
              value: 'S_6_2',
              text: 'I am open and honest in my communication',
            },
            {
              value: 'S_6_3',
              text: 'I balance the what and how in driving results',
            },
            {
              value: 'S_6_4',
              text: 'I value, respect, and recognize contributions',
            },
            {
              value: 'S_6_5',
              text: 'I express emotions and demonstrate vulnerability',
            },
          ],
          rowsOrder: 'random',
          isAllRowRequired: true,
        },
        {
          type: 'comment',
          name: 'S_8_A',
          title:
            'What specific behaviors should I continue demonstrating that have helped me to be effective?',
          hideNumber: true,
          isRequired: true,
        },
        {
          type: 'comment',
          name: 'S_8_B',
          title:
            'What specific behaviors should I stop demonstrating in order to be more effective?',
          hideNumber: true,
          isRequired: true,
        },
        {
          type: 'comment',
          name: 'S_8_C',
          title:
            'What specific behaviors should I start demonstrating in order to be more effective?',
          hideNumber: true,
          isRequired: true,
        },
      ],
    },
  ],
  showPrevButton: false,
  showQuestionNumbers: 'off',
};

export default surveyJSON;
